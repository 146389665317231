import React from 'react'
import {I18n, currentUser} from '../../lib/helpers';
import {fetchScaledPrices} from "../services/product-service";

class PriceInfoModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      isLoading: true,
      prices: [],
      unit: props.product.unit || 'ERROR'
    };
  }

  componentDidMount() {
    fetchScaledPrices(this.props.product.uid)
      .then(response => this.setState({ isActive: true, isLoading: false, prices: response.data.sort((current, next) => current.amount - next.amount) }))
      .catch(error => this.setState({ isActive: true, prices: [], isLoading: false }));
  }

  render() {
    return (
      <React.Fragment>

        <h2 className="subtitle">
          { I18n.shopping_cart.scaled_prices.title }
        </h2>

        <div className="content">
          { this.state.prices.length === 0 &&
          <div className="notification">{ I18n.shopping_cart.scaled_prices.not_available_text }</div>
          }

          { this.state.prices.length > 0 &&
          <table className="table is-striped is-fullwidth">
            <thead>
            <tr>
              <th>{ I18n.shopping_cart.scaled_prices.amount }</th>
              <th>{ I18n.shopping_cart.scaled_prices.price }</th>
            </tr>
            </thead>
            <tbody>

            {this.state.prices.map((item, index) =>
              <tr key={index}>
                <td><p>{item.amount}</p></td>
                <td><p>{item.pricePerUnit} {'/ ' + this.state.unit}</p></td>
              </tr>
            )}
            </tbody>
          </table>
          }
        </div>

        { this.props.product.minPrice &&
        <small>{ I18n.shopping_cart.scaled_prices.min_price } {this.props.product.minPrice} { currentUser === undefined ? "EUR" : currentUser.currency }</small>
        }

      </React.Fragment>
    );
  }
}

export default PriceInfoModal
