import React from "react"
import {I18n} from '../../lib/helpers';
import ProductRow from "./ProductRow";
import {addToCart} from "../services/position-service";
import CartAddEvent from '../../events/cart-add-event'
import ProductSearchEvent from '../../events/product-search-event';

class ProductTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      products: props.products,
      alternativeProducts: [],
      recommendedProducts: props.recommendedProducts || [],
      showOnlyAvailable: false
    }
  }

  handleShowOnlyAvailableChange = (event) => {
    const checked = this.state.showOnlyAvailable;
    this.setState({ showOnlyAvailable: !checked });
  };

  handleAddToCart = (product, amount) => {
    if(!amount) {
      amount = 1;
    }
    addToCart(product, amount)
      .then(() => {
        CartAddEvent.dispatch()
      })
      .catch(console.error)
  };

  componentDidMount() {
    ProductSearchEvent.receive().subscribe(event => {

      const showOnlyAvailable = event.showOnlyAvailable;

      const state = {
        products: event.products.filter(p => !p.alternative),
        alternativeProducts: event.products.filter(p => p.alternative)
      }

      if(typeof showOnlyAvailable !== 'undefined') {
        state["showOnlyAvailable"] = showOnlyAvailable;
      }

      if(state.products.length > 0 || state.alternativeProducts.length > 0) {
        window.scrollTo(0, 500) // Scrolling 250 px down
      }

      this.setState(state)
    })
  }

  render () {
    return (
      <React.Fragment>
        <nav className="level is-mobile">
          <div className="level-left">
          </div>

          <div className="level-right">
            <p className="level-item">
              <button className={`${this.state.showOnlyAvailable ? 'button is-small is-primary': 'button is-small'}`} onClick={this.handleShowOnlyAvailableChange}>{ I18n.products.table.titles.show_only_available }</button>
            </p>
          </div>
        </nav>

        { this.state.products.length === 0 && this.state.alternativeProducts.length === 0 &&
          <div className="notification has-text-dark">{ I18n.products.table.content.use_search_notification }</div>
        }

        { this.state.products.length > 0 && !this.state.showOnlyAvailable &&
          this.state.products.map((product) => <ProductRow product={product} key={product.id} handleAddToCart={this.handleAddToCart}/>)
        }

        {
          this.state.alternativeProducts.length > 0 && !this.state.showOnlyAvailable &&
          <div>
            <h2 className="subtitle">{ I18n.products.search.alternative_products_results }</h2>
            { this.state.alternativeProducts.map((product) => <ProductRow product={product} key={product.id} handleAddToCart={this.handleAddToCart}/>) }
          </div>
        }

        { this.state.products.length > 0 && this.state.showOnlyAvailable &&
          this.state.products.filter((p) => p.stock >= 1).map((product) => <ProductRow product={product} key={product.id} handleAddToCart={this.handleAddToCart}/>)
        }

        { this.state.alternativeProducts.length > 0 && this.state.showOnlyAvailable &&
          <div>
            <h2 className="subtitle">{ I18n.products.search.alternative_products_results }</h2>
            { this.state.alternativeProducts.filter((p) => p.stock >= 1).map((product) => <ProductRow product={product} key={product.id} handleAddToCart={this.handleAddToCart}/>) }
          </div>
        }

      </React.Fragment>
    );
  }

}

export default ProductTable
