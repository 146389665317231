import React from 'react';
import {createQuickSearch} from "../services/search-service";
import ProductSearchEvent from "../../events/product-search-event";
import {I18n} from "../../lib/helpers";
import {currentUser} from "../../lib/helpers";

export default class ProductQuickSearch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      productUid: '',
      amountWarning: false,
      isLoading: false
    };
  }

  onProductUidChange = (event) => {
    this.setState({ productUid: event.target.value })
  };

  onSearch = (event) => {
    event.preventDefault();

    const searchParams= {
      productUid: this.state.productUid,
    };

    this.setState({ isLoading: true });

    return createQuickSearch(searchParams)
      .then(response => { ProductSearchEvent.dispatch(response.data); })
      .catch(console.error)
      .finally(() => this.setState({ isLoading: false }))

  };

  isValid = () => {
    const productUid = this.state.productUid;
    return productUid.length >= 4;
  };

  clearFilters = () => {
    const initialState = {
      productUid: '',
      amountWarning: false,
      isLoading: false
    };

    this.setState(initialState)
  };

  render() {
    return (
      <div className="field">
        { this.state.amountWarning === true &&
        <div className="notification is-warning mt-1 mb-1">
          { I18n.products.search.quicksearch.too_many_products_warning }
        </div>
        }

        <div className="control">
          <div className="columns">
            <div className="column is-8">
              <div className="field">
                <form onSubmit={ this.onSearch }>
                  <input lang="de" className="input" placeholder={ typeof currentUser === 'undefined' ? I18n.products.search.quicksearch.article_number_guest : I18n.products.search.quicksearch.article_number} maxLength="22" value={this.state.productUid} onChange={this.onProductUidChange} />
                </form>
              </div>
            </div>
          </div>
          <hr />
          <nav className="level">
            <div className="level-left">
              { this.isValid() ?
                <button className={`button is-primary level-item mb-1 ${this.state.isLoading === true ? 'is-loading': ''}`} onClick={this.onSearch}>{ I18n.products.search.search_button }</button>
                :
                <button className={`button is-primary level-item mb-1`} disabled>{ I18n.products.search.search_button }</button>
              }
            </div>
            <div className="level-right">
              <a className={"button is-grey level-item"} onClick={this.clearFilters}>{I18n.products.search.detailsearch.reset_button}</a>
            </div>
          </nav>

        </div>
      </div>
    );
  }

}
