import React from 'react';
import ProductCategoryChooser from "./ProductCategoryChooser";
import ProductDecimalInput from "./ProductDecimalInput";
import ProductSearchEvent from '../../events/product-search-event';
import ProductOptionInput from "./ProductOptionInput";
import {createSearch} from "../services/search-service";
import ProductQuickSearch from "./ProductQuickSearch";
import {I18n} from "../../lib/helpers";

export default class ProductSearch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      categories: props.categories,
      filters: [],
      isLoading: false,
      tolerance: true,
      meta: { totalPages: 0, totalRecords: 0, currentPage: 1, nextPage: 2, alternativeRecords: 0 },
      activeTab: 'details',
    };
  }

  componentDidMount() {
    const index = this.state.categories.findIndex(c => c.active );
    if( index === -1) { return }
    const category = this.state.categories[index];
    this.setState({filters: category.filters});
  }

  changeTab = (value) => {
    ProductSearchEvent.dispatch({ products: [], showOnlyAvailable: false });
    if (value === 'quick') {
      return this.setState({ activeTab: value, filters: [] })
    }

    const index = this.state.categories.findIndex(c => c.active );
    if( index === -1) { return }
    const category = this.state.categories[index];
    this.setState({filters: category.filters, activeTab: value});
  };

  handleCategorySelect = (category) => {
    let categories = this.state.categories;
    categories = categories.map(c => {
      c.active = false;
      return c;
    });
    category.active = true;
    const index = categories.findIndex(c => c.id === category.id);
    if (index === -1) { return; }
    categories.splice(index, 1, category);
    this.setState({ categories: categories, filters: category.filters, tolerance: category.uid === "OR" });
  };

  handleFilterInput = (filter) => {
    const filters = this.state.filters;
    const index = filters.findIndex(f => f.id === filter.id);
    if (index === -1) { return }
    filters.splice(index, 1, filter);
    this.setState({ filters: filters });
  };

  fetchProducts = (page = 1) => {
    if(!this.isValid()) return;

    this.setState({ isLoading: true });
    const filters = this.state.filters;
    const tolerance = this.state.tolerance;
    return createSearch(null, filters, page, tolerance)
      .then(response => {
        ProductSearchEvent.dispatch({ products: response.data.products });
        this.setState({meta: response.data.meta})
      })
      .catch(console.error)
      .finally(() => this.setState({ isLoading: false }))
  };

  isValid = () => {
    // Only if every filter is valid, we can display the button.
    // This allows us to display the button. If filter were a class, we could easiliy call "validate()" on it.
    // This may be an answer.
    const filters = this.state.filters;

    return filters.every((filter) => {
      if( filter.format === 'decimal' && filter.required) {

        return (filter.input.min !== '') && (parseFloat(filter.input.min) >= 0) && (parseFloat(filter.input.max) > 0) && (parseFloat(filter.input.max) >= parseFloat(filter.input.min))
      }
      if( filter.format === 'option' && filter.required) {
        return filter.input.values && filter.input.values.length > 0
      }
      return true
    });
  };

  toggleTolerance = () => {
    this.clearFilters();
    const tolerance = this.state.tolerance;
    this.setState({ tolerance: !tolerance })
  }

  clearFilters = () => {
    const filters = this.state.filters;

    const resettedFilters = filters.map(filter => {

      if(filter.format === 'decimal') {
        filter.input = {min: null, max: null};
      }

      if (filter.format === 'option') {
        filter.input = {};
      }

      return filter;
    });

    this.setState({ filters: resettedFilters })
  };

  render() {
    return(
      <React.Fragment>
        <div className="box mb-2">

          <div className="tabs is-toggle is-fullwidth mb-3">
            <ul>
              <li className={`${this.state.activeTab === 'details' ? 'is-active': ''}`} onClick={() => this.changeTab("details")}>
                <a>
                  <span className="icon"><i className="fas fa-search-plus" aria-hidden="true"></i></span>
                  <span>{ I18n.products.search.detailsearch.title }</span>
                </a>
              </li>
              <li className={`${this.state.activeTab === 'quick' ? 'is-active': ''}`} onClick={() => this.changeTab("quick")}>
                <a>
                  <span className="icon"><i className="fas fa-search" aria-hidden="true"></i></span>
                  <span>{ I18n.products.search.quicksearch.title }</span>
                </a>
              </li>
            </ul>
          </div>
          { this.state.activeTab === 'details' &&
          <div>
            <div className="columns">
              <div className="column is-2-desktop is-3-tablet">
                <p className="is-size-6 has-text-weight-bold mb-1">{ I18n.products.search.detailsearch.article_categories }:</p>
                <ProductCategoryChooser categories={this.state.categories} onCategorySelect={this.handleCategorySelect} />
              </div>
              <div className="column is-6-desktop is-5-tablet">
                <p className="is-size-6 has-text-weight-bold mb-1">{ I18n.products.search.detailsearch.article_attributes_values }:</p>
                { this.state.filters
                  .filter(filter => filter.format === 'decimal')
                  .map(filter => <ProductDecimalInput filter={filter} tolerance={this.state.tolerance} handleFilterInput={this.handleFilterInput} key={filter.id} onEnter={() => this.fetchProducts(1)} />) }
                { this.state.categories.findIndex((category) => category.uid === "OR" && category.active ) !== -1 &&
                <button type="button" className={`button is-small ${this.state.tolerance ? 'is-primary' : ''}`} onClick={this.toggleTolerance}>{ I18n.products.search.tolerance_enable_button }</button>
                }
              </div>
              <div className="column is-4-desktop is-4-tablet">
                <p className="is-size-6 has-text-weight-bold mb-1">{ I18n.products.search.detailsearch.article_attributes_multiselect }:</p>
                { this.state.filters
                  .filter(filter => filter.format === 'option')
                  .map(filter => <ProductOptionInput filter={filter} handleFilterInput={this.handleFilterInput} key={filter.id} />) }
              </div>
            </div>
            <hr/>
            <nav className="level">
              <div className="level-left">
                { this.isValid() ?
                  <React.Fragment>
                    <a className={`button is-primary level-item ${this.state.isLoading ? 'is-loading': ''}`} onClick={() => this.fetchProducts(1)}>{ I18n.products.search.search_button }</a>
                    { this.state.meta.currentPage > 1 &&
                    <a className={`button level-item`} onClick={() => this.fetchProducts(this.state.meta.previousPage)}>{ I18n.products.search.previous_page }</a>
                    }
                    { this.state.meta.totalPages > 0 && this.state.meta.totalPages > this.state.meta.currentPage &&
                    <a className={`button level-item`} onClick={() => this.fetchProducts(this.state.meta.nextPage)}>{ I18n.products.search.next_page }</a>
                    }
                  </React.Fragment>
                  :
                  <button className={`button is-primary`} disabled>{ I18n.products.search.search_button }</button>
                }
              </div>
              {
                <div className="level-right">
                  <a className={"button is-grey level-item"} onClick={this.clearFilters}>{I18n.products.search.detailsearch.reset_button}</a>
                </div>
              }
            </nav>
            <label className="label is-small has-text-grey">{ I18n.products.search.amount_found_products }: {this.state.meta.totalRecords} * { I18n.products.search.amount_found_alternative_products }: {this.state.meta.alternativeRecords} * { I18n.products.search.amount_pages }: {this.state.meta.totalPages} * { I18n.products.search.current_page }: {this.state.meta.currentPage} </label>
          </div>
          }

          { this.state.activeTab === 'quick' &&
            <ProductQuickSearch handleFilterInput={this.handleFilterInput} userExists={this.props.userExists}></ProductQuickSearch>
          }

        </div>
      </React.Fragment>
    );
  }

}
