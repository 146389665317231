import React from 'react';
import {currentUser, I18n} from "../../lib/helpers";
import PriceInfoModal from "../shared/PriceInfoModal";

export default class ProductDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product: props.product
    }
  }

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="columns">
          <div className="column is-5">
            <div className="columns">
              <div className="column">
                <img style={{maxWidth: "200px"}} className="image" src={this.state.product.image} alt="schematic image" />
                <br/>
                <h1 className="title is-size-5"> {this.state.product.nameOne} {this.state.product.nameTwo} {this.state.product.nameThree} {this.state.product.nameFour}</h1>
                <h2 className="subtitle mt-0 mb-0"> {I18n.product.product_number}: {this.state.product.uid}</h2>
                {typeof currentUser !== 'undefined' && this.state.product.customerProductUid &&
                <h2 className="subtitle mt-0 mb-0"> {I18n.product.your_product_number}: {this.state.product.customerProductUid}</h2>
                }
                {this.state.product.conformity &&
                <h2 className="subtitle mt-0 mb-0">{I18n.product.product.conformity}: {this.state.product.conformity}</h2>
                }
                {this.state.product.compound && this.state.product.attachment &&
                <h2 className="subtitle mt-0 mb-0">{I18n.product.compound}: <a target="_blank" style={ { "textDecoration": "underline" } } href={`${this.state.product.attachment}`}>{this.state.product.compound}</a></h2>
                }
                {this.state.product.compound && !this.state.product.attachment &&
                <h2 className="subtitle mt-0 mb-0">{I18n.product.compound}: {this.state.product.compound}</h2>
                }

              </div>
            </div>
          </div>
          <div className="column">
            <PriceInfoModal product={this.state.product} />
          </div>
        </div>
        <div className="tags are-medium">

          { typeof currentUser === 'undefined' &&
            <span className="tag is-warning is-small">
              <a className="has-text-grey-darker" href="/user_sessions/new"/>
              <i className="fa fa-euro-sign mr-1"/> {I18n.product.login_for_prices}
            </span>
          }

          {this.state.product.weight && this.state.product.weight !== 0 &&
            <span className="tag is-primary is-small">
              <i className="fas fa-weight-hanging mr-1"/>
                  <p>{this.state.product.weight} &nbsp; {I18n.product.kilogram}</p>
              {
                this.state.product.weight && this.state.product.weight !== 0 || <p>{I18n.product.na}</p>
              }
            </span>
          }

          {this.state.product.hsCode &&
            <span className="tag is-primary is-small">
              <i className="fas fa-ship mr-1"/>
                <p>{this.state.product.hsCode}</p>
              {
                this.state.product.weight && this.state.product.weight !== 0 || <p>{I18n.product.na}</p>
              }
            </span>
          }

          {this.state.product.origin &&
            <span className="tag is-primary is-small">
              <i className="fas fa-globe mr-1"/>
              <p>{this.state.product.origin}</p>
            </span>
          }

        </div>
      </React.Fragment>
    )
  }
}
