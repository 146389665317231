import React from 'react';
import {I18n} from "../../lib/helpers";

export default class ProductDecimalInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: props.filter
    }
  }


  // Look at this example and this issue on react: https://codepen.io/whazam/pen/oOWxPK or https://github.com/facebook/react/issues/13752 or https://sharif.io/better-react-number-input/
  // In short, firefox is just implementing the html spec, chrome does some monkey patching, which fixes our problem. (2,5 is not a valid floating point number)
  // What the html spec says: "The value attribute, if specified and not empty, must have a value that is a valid floating-point number. The value sanitization algorithm is as follows: If the value of the element is not a valid floating-point number, then set it to the empty string instead."

  // As a consequence, we have to handle this by ourself.
  // We are setting the input field to text and only allow values which are numbers. (Regex)
  // With a "keypress" event we intercept the user input and change the values according to this function.
  // The result is always a dot, that is also the correct floating point representation in ruby (backend)
  formatText = e => {
    const NUMBER_DOT_COMMA = /^[\d,.]*$/;
    const fieldValue = e.target.value;
    const fieldHasCommaOrDot = fieldValue.includes('.') || fieldValue.includes(',');
    const keyIsCommaOrDot = e.key === '.' || e.key === ',';

    if (!NUMBER_DOT_COMMA.test(e.key) || (keyIsCommaOrDot && fieldHasCommaOrDot))
      e.preventDefault();
    e.target.value = fieldValue.replace(',', '.');
  };

  onMaxChange = (event) => {
    const filter = this.state.filter;
    filter.input.max = event.target.value;
    this.props.handleFilterInput(filter);
    this.setState({ filter: filter });
  };

  onMinChange = (event) => {
    const filter = this.state.filter;

    if(filter.input.max === '' || filter.input.max === filter.input.min) {
      filter.input.max = event.target.value;
    }

    filter.input.min = event.target.value;

    this.props.handleFilterInput(filter);
    this.setState({ filter: filter });
  };

  onEnterPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onEnter();
    }
  };

  render() {
    return (
      <div className="field-body mb-1">
        <div className="field">
          <label className="label is-small has-text-grey">{ this.props.filter.name } {this.props.filter.required && I18n.products.search.detailsearch.required_field } </label>
          <div className="control is-expanded">
            <input lang="de" step="0.1" value={ this.state.filter.input.min || ''} className="input" name="min" placeholder={!this.props.tolerance ? "Min." : ""} type="text" onKeyPress={this.formatText} onChange={this.onMinChange} onKeyUp={ this.onEnterPress } />
          </div>
        </div>
        <div className="field">
          <label className="label is-small has-text-grey">{ this.props.filter.name } { this.props.filter.required && I18n.products.search.detailsearch.required_field }</label>
          <div className="control is-expanded">
            <input disabled={this.props.tolerance}  placeholder={!this.props.tolerance ? "Max." : ""} lang="de" step="0.1" value={ this.state.filter.input.max || ''} className="input" name="max" type="text" onKeyPress={this.formatText} onKeyUp={ this.onEnterPress } onChange={this.onMaxChange} />
          </div>
        </div>
      </div>
    );
  }

}
