import React from 'react';
import ProductAvailabilityChecker from "./ProductAvailabilityChecker";
import {I18n} from "../../lib/helpers";
import PriceInfoModal from "../shared/PriceInfoModal";
import {addToCart} from "../services/position-service";
import CartAddEvent from "../../events/cart-add-event";

// <ProductPriceLevel amount={1} product={myProduct}></ProductPriceLevel>
export default class ProductPriceLevel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product: props.product,
      isValid: props.amount > 0,
      isAvailable: props.product.stock > 0,
      addedToCart: false,
      amount: props.amount
    }
  }

  handleAvailabilityChange = (availability) => {
    const product = this.state.product;
    product.price = availability.price;
    this.setState({isAvailable: availability.available, amount: availability.amount, product: product})
  };

  onAddToCart = () => {
    this.setState({addedToCart: true});
    setTimeout(() => this.setState({addedToCart: false}), 1500);
    addToCart(this.state.product, this.state.amount)
      .then(() => {
        CartAddEvent.dispatch()
      })
      .catch(console.log)
  };

  render() {
    return (
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <span data-balloon-pos="up" aria-label={`${this.state.isAvailable ? I18n.product.available : I18n.product.not_available + ", " + I18n.product.on_stock + ": " + this.state.product.stock} `} className="is-white is-medium">
              <span className={`icon ${this.state.isAvailable ? 'has-text-success': 'has-text-warning'} mr-1`}>
                <i className="fas fa-circle"></i>
              </span>
            </span>
            {
              !this.state.product.price &&
              <span>{" "}{I18n.shopping_cart.my_shopping_cart.price_on_request}</span>
            }
            { this.state.product.price &&
              <span>
                {this.state.product.price.pricePerUnit} {this.state.product.price.blanket ? I18n.products.table.titles.blanket_price: ` / ${this.state.product.unit}`}
              </span>
            }
          </div>
          <div className="level-item">
            <PriceInfoModal product={this.state.product} />
          </div>
          <div className="level-item">
            <ProductAvailabilityChecker uid={this.state.product.uid} amount={1} onAvailabilityChange={this.handleAvailabilityChange}></ProductAvailabilityChecker>
          </div>
          <div className="level-item">
            { this.state.addedToCart &&
            <button name="button" className="button is-success is-rounded is-small">
                  <span className="icon pl-1 pr-1">
                    <i className="fas fa-info-circle"></i>
                  </span>
              <span>{I18n.products.table.content.added_to_shopping_cart}</span>
            </button>
            }

            { !this.state.addedToCart &&
            <button name="button" className="button is-primary is-rounded is-small" onClick={this.onAddToCart}>
                <span className="icon pl-1 pr-1">
                  <i className="fas fa-shopping-cart"></i>
                </span>
              <span>{I18n.products.table.content.add_to_shopping_cart}</span>
            </button>
            }
          </div>
        </div>
      </nav>
    );
  }

}
