import React from 'react';
import {I18n} from "../../lib/helpers";
import {debounce} from "throttle-debounce";
import {checkAvailability} from "../services/product-service";

// Interface <ProductAvailabilityChecker uid={123123} amount={1} onAvailabilityChange{myFunction}></ProductAvailabilityChecker>
export default class ProductAvailabilityChecker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
      isLoading: false,
      amount: props.amount,
    }
  }

  componentWillUnmount() {
    this._checkAvailabilityDebounced.cancel(); // or flush() in case we need to fire handler immediately.
  }

  handleOnChange = (event) => {
    const amount = event.target.value;
    this.setState({amount: amount, isLoading: true});
    this._checkAvailabilityDebounced(amount);
  };

  _checkAvailabilityDebounced = debounce(300, (amount) => {
    checkAvailability(this.props.uid, amount)
      .then(res => {
        this.setState({ isLoading: false, isValid: true });

        if (this.props.onAvailabilityChange) {
          this.props.onAvailabilityChange({amount: amount, available: res.data.available, price: res.data.price});
        }

      })
      .catch(() => this.setState({ isLoading: false, isValid: false, isAvailable: false }))
  });

  render() {
    return (
      <React.Fragment>
        <div className="field">
          <div className="field-body">
            <div className="field">
              <div className={`control is-small ${this.state.isLoading ? 'is-loading' : ''}`}>
                <input type="text" className={`input is-small special-input-width is-rounded ${this.state.isValid ? '' : 'is-danger'}`} onChange={this.handleOnChange} value={this.state.amount} placeholder={ I18n.shopping_cart.my_shopping_cart.amount }></input>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

}
